import { useState } from 'react'
import { useQuery } from '@apollo/client'
import chunk from 'lodash.chunk'
import readXlsxFile from 'read-excel-file'
import styled, { css } from 'styled-components/macro'

import { BottomSheet } from '@/components/BottomSheet'
import { DangerColor } from '@/components/Colors'
import { InnocuousButton } from '@/components/ExtraButtons'
import { InlineModalFooter, InlineModalSection } from '@/components/InlineModal'
import { T, useLanguageContext } from '@/modules/Language'
import participantQueries from '@/modules/ParticipantsList/ParticipantsList.queries'
import { useParticipantsListContext } from '@/modules/ParticipantsList/ParticipantsListState'

import {
  LanguagesRegistryQuery,
  LanguagesRegistryQueryVariables,
  SaleAccommodationBaseQuery,
  SaleAccommodationBaseQueryVariables,
  SuitableFilter,
} from '~generated-types'

import {
  CopyTemplateButton,
  FileDropArea,
  ImportButton,
  ParticipantsTable,
} from './components'
import { AvailableRoom, CreateInputKey, Participant } from './ExcelImport.types'
import { parseFileSchema, parseTable } from './utils'

type Props = {
  closeModal: () => void
}

export const ExcelImport = ({ closeModal }: Props) => {
  const { language } = useLanguageContext()
  const { salesId, handleAddParticipants, refetchParticipants } =
    useParticipantsListContext()

  const [availableRooms, setAvailableRooms] = useState<AvailableRoom[]>([])
  const [fileSchema, setFileSchema] = useState<(CreateInputKey | null)[]>([])
  const [isFileUploaded, setFileUploaded] = useState<boolean>(false)
  const [isProcessing, setProcessing] = useState<boolean>(false)
  const [participants, setParticipants] = useState<Participant[]>([])

  const { data } = useQuery<
    LanguagesRegistryQuery,
    LanguagesRegistryQueryVariables
  >(participantQueries.LANGUAGES_REGISTRY, {
    variables: { locale: language },
  })

  const languages = data?.registry?.registryLanguages ?? []

  useQuery<SaleAccommodationBaseQuery, SaleAccommodationBaseQueryVariables>(
    participantQueries.SUITABLE_ROOMS_BASE,
    {
      fetchPolicy: 'no-cache',
      onCompleted: ({ suitableRooms }) =>
        setAvailableRooms(
          suitableRooms.map(({ roomReservation }) => ({
            id: roomReservation.id,
            number: roomReservation.request.room.number,
          }))
        ),
      variables: {
        input: {
          filter: SuitableFilter.Default,
          salesId: salesId,
        },
      },
    }
  )

  const handleImportParticipants = () => {
    setProcessing(true)

    const update = async () => {
      const input = participants.map(({ roomNumber, ...createInput }) => {
        const availableRoom = availableRooms.find(
          ({ number }) => number === roomNumber
        )

        return availableRoom
          ? { ...createInput, roomReservationId: availableRoom.id }
          : createInput
      })

      const inputChunks = chunk(input, 100)

      for (const chunk of inputChunks) {
        await handleAddParticipants(chunk)
      }
    }

    return update()
      .then(refetchParticipants)
      .then(closeModal)
      .catch(() => setProcessing(false))
  }

  const handleUpload = (files: FileList) =>
    readXlsxFile([...files][0]).then((rows) => {
      const fileSchema = parseFileSchema(rows[0])

      setFileSchema(fileSchema)
      setParticipants(parseTable(rows.slice(1), fileSchema, { languages }))
      setFileUploaded(true)
    })

  return (
    <BottomSheet
      onClose={closeModal}
      title={<T>ParticipantsList:Import.importParticipants</T>}
    >
      {!isFileUploaded ? (
        <DropAreaContainer>
          <CopyTemplateButton />
          <FileDropArea handleUpload={handleUpload} />
        </DropAreaContainer>
      ) : (
        <>
          <ParticipantsTableContainer>
            <ImportButton
              isProcessing={isProcessing}
              onClick={handleImportParticipants}
            />
            <ParticipantsTable
              fileSchema={fileSchema}
              languages={languages}
              participants={participants}
            />
          </ParticipantsTableContainer>

          <div style={{ flex: 1 }} />

          <InlineModalFooter justifyContent="flex-end">
            <InnocuousButton onClick={closeModal}>
              <DangerColor>
                <T>common:action.cancel</T>
              </DangerColor>
            </InnocuousButton>
          </InlineModalFooter>
        </>
      )}
    </BottomSheet>
  )
}

////////

const DropAreaContainer = styled(InlineModalSection)`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex: 1;

  ${({ theme }) => css`
    margin: ${theme.spacing.gu(1)}rem 0 ${theme.spacing.gu(8)}rem;
    padding: 0 ${theme.spacing.gu(8)}rem;
  `}
`

const ParticipantsTableContainer = styled(InlineModalSection)`
  align-items: flex-end;
  flex-direction: column;
  margin: 0;
  overflow: auto;

  ${({ theme }) => css`
    padding: ${theme.spacing.gu(1)}rem ${theme.spacing.gu(8)}rem
      ${theme.spacing.gu(6)}rem;
  `}
`
